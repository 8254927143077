import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  followReceipt (id) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'followItemReceipt',
      data: {
        item_receipt_id: id,
      },
    })
  },
  unFollowReceipt (id) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'unfollowItemReceipt',
      data: {
        item_receipt_id: id,
      },
    })
  },
  isFollowReceipt (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'isFollowReceipt' + '/' + id,
    })
  },
  followReceiptItem (id) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'followItemReceiptItem',
      data: {
        item_receipt_item_id: id,
      },
    })
  },
  unFollowReceiptItem (id) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'unfollowItemReceiptItem',
      data: {
        item_receipt_item_id: id,
      },
    })
  },
  isFollowReceiptItem (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'isFollowReceiptItem' + '/' + id,
    })
  },
}
