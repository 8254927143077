import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (search, specifyKeyword, keyword) {
    if (search) {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'manual',
        params: {
          is_specify_keyword: specifyKeyword,
          key_word: keyword,
        },
      })
    } else {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'manual',
      })
    }
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'manual/' + id,
    })
  },
  update (data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'manual/' + data.id,
      data: {
        name: data.name,
        description: data.description,
        // item_type_category: data.itemTypeCategory,
        // item_type_name: data.itemTypeName,
        item_type_id: data.itemTypeId,
        specify_keyword: data.specifyKeyword,
        document_url: data.documentUrl,
      },
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'manual',
      data: {
        name: data.name,
        description: data.description,
        // item_type_category: data.itemTypeCategory,
        // item_type_name: data.itemTypeName,
        item_type_id: data.itemTypeId,
        specify_keyword: data.specifyKeyword,
        document_url: data.documentUrl,
      },
    })
  },
  delete (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'manual/' + id,
    })
  },
  selectManual (itemTypeId) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'manual/select',
      data: {
        item_type_id: itemTypeId,
      },
    })
  },
  loadPageData (page, url, search, keyWord) {
    if (search) {
      return axios({
        method: 'get',
        url: url + '?page=' + page,
        params: {
          key_word: search,
        },
      })
    } else {
      return axios({
        method: 'get',
        url: url + '?page=' + page,
      })
    }
  },
}
