import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  store (receiptId, id, comment) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceiptItemComment',
      data: {
        item_receipt_id: receiptId,
        item_receipt_item_id: id,
        content: comment,
      },
    })
  },
  destroy (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'itemReceiptItemComment/' + id,
    })
  },
}
