<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <snack-bar ref="snackBar" />
    <breadcrumbs :items="breadcrumbsItems" />
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <material-card
          icon="mdi-clipboard-text"
          icon-small
          :title="receiptItemData.item_part_number"
          color="accent"
        >
          <v-data-table
            dense
            :headers="receiptItemTestTableHeader"
            :items="receiptItemData.item_test"
            :item-class="markSpecialItem"
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>BIN: {{ receiptItemData.item_bin }}</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-toolbar-title>Status: {{ statusName }}</v-toolbar-title>
                <v-spacer />
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Post Result
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form
                          ref="testResultForm"
                          v-model="testResultFormValid"
                          lazy-validation
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.tested_quantity"
                                :rules="testedQuantity"
                                label="Tested Quantity"
                                type="number"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.failed_in_testing"
                                label="Failed in Testing"
                                type="number"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-select
                                v-model="editedItem.task_status_id"
                                :items="task_status"
                                :rules="taskStatusRules"
                                label="Status"
                                item-text="name"
                                item-value="id"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-text-field
                                v-model="editedItem.test_server"
                                label="Server Name Test Item Use (Use , Separate Each Server)"
                                :rules="testServerRules"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-textarea
                                v-model="editedItem.comment"
                                name="input-7-1"
                                label="Comment"
                                hint="Hint text"
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :disabled="!testResultFormValid"
                              color="blue darken-1"
                              text
                              @click="saveValidate"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!--  Item delete confirm  -->
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title class="headline">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
              <!--  Item type check confirm  -->
              <v-dialog
                v-model="dialogConfirmType"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ confirmTypeMessage }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      color="accent"
                      text
                      @click="submitSaveData"
                    >
                      I'm Sure
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <!--
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              -->
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-card-text>
            Total Quantity: {{ receiptItemData.label_show_quantity }}
            <ul v-if="receiptItemData.sos_line_ids">
              <li
                v-for="(item, key) in receiptItemData.sos_line_ids"
                :key="key"
              >
                Line #: {{ item.id }}, QTY : {{ item.qty }}
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            <h5>Activity Show:</h5>
            <v-row class="px-2 py-3">
              <v-chip
                class="ma-2"
                small
                @click="commentType='comment'"
              >
                Comments
              </v-chip>
              <v-chip
                class="ma-2"
                small
                @click="commentType='itemReceiptComment'"
              >
                Receipt Comments
              </v-chip>
              <v-spacer />
              <v-chip
                class="ma-2"
                small
                @click="commentType='record'"
              >
                Testing Log
              </v-chip>
            </v-row>
            <v-card
              v-for="comment in displayComment"
              :key="comment.id"
            >
              <v-card-subtitle
                v-if="commentType !== 'receiptItemLog'"
              >
                {{ comment.user.name }}
              </v-card-subtitle>
              <v-card-text>
                {{ comment.content }}
              </v-card-text>
              <v-card-actions>
                Update at: {{ comment.created_at }}
                <v-spacer />
                <v-btn
                  v-if="commentType === 'comment' && 1 === 1"
                  text
                  small
                  @click="deleteComment(comment.id)"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <material-card>
          <v-card-text>
            Category: {{ categoryName }}
            <br>
            Item Type:
            <v-chip
              v-if="itemType.requestCheck===true"
              color="info"
            >
              {{ itemType.name }}
            </v-chip>
            <v-chip v-if="itemType.requestCheck===false">
              {{ itemType.name }}
            </v-chip>
            <br>
            Is SOS Receipt Item: {{ receiptItemData.is_sos_order === 1 ? 'Yes' : 'No' }}
            <br>
            Request Due Data: {{ receiptItemData.request_due_date }}
            <br>
            Estimate Due Data: {{ receiptItemData.estimate_due_date }}
            <v-select
              v-model="editedItem.task_status_id"
              class="mt-2"
              dense
              outlined
              label="Change Task Status To"
              item-text="name"
              item-value="id"
              :items="taskStatusNoAll"
              @change="resetStatus()"
            />
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <follow-button :info="followInfo" />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  class="mr-0 mt-2"
                  color="secondary "
                  min-width="100"
                  block
                  @click="syncItem()"
                >
                  Sync Item
                </v-btn>
              </v-col>
            </v-row>
            <v-card class="my-2">
              <v-card-title>
                Quick Update
              </v-card-title>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="editedItem.test_server"
                  label="Server Name Test Item Use"
                  required
                />
                <h5>Note: Use , Separate Each Server</h5>
              </v-col>
              <v-btn
                class="mr-0 mt-2"
                color="primary"
                min-width="100"
                block
                @click="allPass()"
              >
                All Pass
              </v-btn>
            </v-card>
            <v-row align="center">
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="comment"
                  label="Comment:"
                  auto-grow
                  hint="This comment is po comment"
                  clearable
                  clear-icon="mdi-close-circle"
                />
              </v-col>
            </v-row>
            <v-btn
              class="mr-0 mt-2"
              color="green"
              min-width="100"
              block
              @click="commentReceiptItem"
            >
              Post Comment
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-divider />
            <h3 class="mt-2 mb-2 align-center">
              Item Test Manual
            </h3>
            <v-data-table
              :headers="manualHeaders"
              :items="manualData"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="showManual(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </material-card>
        <div class="py-3" />
      </v-col>
    </v-row>
    <div class="text-center">
      <item-manual-view
        ref="itemManualView"
        :manual="manual"
      />
    </div>
  </v-container>
</template>

<script>
  import MaterialCard from '../components/MaterialCard'
  import Breadcrumbs from '@/layouts/default/widgets/Breadcrumbs'
  import FollowButton from '../layouts/default/widgets/FollowButton'
  import receiptItem from '../api/item-receipt-item'
  import ItemApi from '@/api/item'
  import taskStatus from '../api/task-status'
  import receiptItemTest from '../api/item-receipt-item-test'
  import receiptItemCommentApi from '@/api/receipt-item-comment'
  import receiptItemDataHandle from '@/util/receipt-item-data-handle'
  import manualApi from '@/api/manual'
  import ItemManualView from '@/components/ItemManualView'
  import { get } from 'vuex-pathify'
  import store from '@/store'
  export default {
    name: 'ReceiptItem',
    components: { MaterialCard, Breadcrumbs, FollowButton, ItemManualView },
    data () {
      return {
        itemReceiptItemId: 0,
        receiptItemData: { },
        comment: '',
        statusName: '',
        categoryName: '',
        itemType: {
          name: '',
          requestCheck: false,
        },
        receiptItemTestTableHeader: [
          {
            text: 'id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Charge By', value: 'user.name' },
          { text: 'Tested', value: 'tested_quantity' },
          { text: 'Passed', value: 'pass_in_testing' },
          { text: 'Failed', value: 'failed_in_testing' },
          { text: 'Tested On', value: 'test_server' },
          { text: 'Created', value: 'created_at' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        task_status: [],
        editedIndex: -1,
        dialog: false,
        dialogDelete: false,
        dialogConfirmType: false,
        confirmTypeMessage: null,
        testResultFormValid: true,
        editedItem: {
          item_receipt_item_id: 0,
          tested_quantity: 0,
          failed_in_testing: 0,
          test_server: '',
          task_status_id: '',
          comment: null,
        },
        defaultItem: {
          item_receipt_item_id: 0,
          tested_quantity: 0,
          failed_in_testing: 0,
          test_server: '',
          task_status_id: '',
          comment: null,
        },
        taskStatusRules: [
          v => !!v || 'Task status muse be select',
          v => (v > 0) || 'Task status can not be 0',
        ],
        testedQuantity: [
          v => !!v || 'Test quantity can not be null',
          v => (v > 0) || 'Test quantity can not be  0 or negative',
        ],
        testServerRules: [
          v => !!v || 'Test Server can not be null',
        ],
        commentType: 'comment',
        displayComment: {},
        newComment: '',
        breadcrumbsItems: [],
        followInfo: {},
        manualHeaders: [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
          },
          { text: 'Action', value: 'actions', sortable: false },
        ],
        manualData: [],
        manualSearch: '',
        // manualShowDialog: false,
        manual: {},
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Test Result' : 'Edit Test Result'
      },
      ...get('task', ['taskStatusNoAll']),
    },
    watch: {
      itemReceiptItemId (val) {
        if (val !== 0) {
          this.receiptItemInfo(val)
          this.editedItem.item_receipt_item_id = val
          this.defaultItem.item_receipt_item_id = val
          this.followInfo.id = val
          this.followInfo.dataType = 'receiptItem'
        }
      },
      dialog (val) {
        val || this.close()
      },
      commentType (val) {
        this.displayCommentData()
      },
    },
    created () {
      this.itemReceiptItemId = this.$route.params.id
      this.taskStatusIndex()
    },
    methods: {
      receiptItemInfo (id) {
        receiptItem.show(id).then(response => {
          this.receiptItemData = receiptItemDataHandle.dateFormatSingle(response.data.data)
          this.statusName = this.receiptItemData.task_status.name
          this.categoryName = this.receiptItemData.category.name
          this.itemType.name = this.receiptItemData.item_type.name
          this.itemType.requestCheck = this.receiptItemData.item_type.request_check
          this.generateBreadCrumbs()
          this.displayCommentData()
          this.loadManual()
        }).catch(error => {
          console.log(error)
        })
      },
      generateBreadCrumbs () {
        this.breadcrumbsItems = []
        const table = {
          text: 'Item Receipt Table',
          disabled: false,
          to: { name: 'Item Receipt' },
        }
        const receipt = {
          text: this.receiptItemData.item_receipt.po_number,
          disabled: false,
          to: { name: 'Receipt Show', params: { id: this.receiptItemData.item_receipt.id } },
        }
        const receiptItem = {
          text: this.receiptItemData.item_part_number,
          disabled: true,
          to: { name: 'Receipt Item', params: { id: this.receiptItemData.id } },
        }
        this.breadcrumbsItems.push(table)
        this.breadcrumbsItems.push(receipt)
        this.breadcrumbsItems.push(receiptItem)
      },
      taskStatusIndex () {
        taskStatus.index().then(response => {
          this.task_status = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      markSpecialItem (item) {
        if (item.removed_at) return 'table-remove-item'
        if (item.failed_in_testing > 0) return 'table-issue-item'
      },
      resetStatus () {
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Reset status begin, waiting until status change',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
        const data = {
          id: this.receiptItemData.id,
          task_status_id: this.editedItem.task_status_id,
        }
        receiptItem.resetStatus(data).then(() => {
          this.receiptItemInfo(this.itemReceiptItemId)
        }).finally(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
      loadManual () {
        const result = []
        if (this.receiptItemData.item_info) {
          const typeInfo = this.receiptItemData.item_info
          if (typeInfo.specify_keyword) {
            manualApi.index(true, true, typeInfo.specify_keyword).then(response => {
              response.data.data.forEach((element, key) => { result.push(element) })
            }).catch(error => { console.log(error) })
          }
          manualApi.selectManual(typeInfo.item_type_id).then(response => {
            response.data.data.forEach((element, key) => { result.push(element) })
          }).catch(error => { console.log(error) })
        }
        this.manualData = result
      },
      showManual (item) {
        manualApi.show(item.id).then(response => {
          this.manual = response.data.data
          // this.manualShowDialog = true
          this.$refs.itemManualView.manualShowDialog = true
        }).catch(error => { console.log(error) })
      },
      syncItem () {
        const sosItemId = this.receiptItemData.sos_item_id
        ItemApi.syncItemFromSos(sosItemId).then(() => {
          store.set('message/storeMessage', {
            color: 'info',
            text: 'Sync data successful, now load new item info',
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
          this.this.receiptItemInfo(this.itemReceiptItemId)
        }).catch(error => {
          console.log(error)
        })
      },
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      close () {
        this.dialogConfirmType = false
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      saveValidate () {
        if (this.$refs.testResultForm.validate()) this.save()
      },
      allPass () {
        this.editedItem.item_receipt_item_id = this.$route.params.id
        this.editedItem.tested_quantity = this.receiptItemData.label_show_quantity
        this.editedItem.task_status_id = 3
        if (this.editedItem.test_server.length > 0) {
          this.save()
        } else {
          store.set('message/storeMessage', {
            color: 'error',
            text: 'Request test server name, when you submit testing result',
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }
      },
      save () {
        if (this.editedIndex > -1) {
          // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          if (this.itemType.requestCheck === true || this.receiptItemData.is_request === true) {
            if (this.itemType.requestCheck === true && this.receiptItemData.is_request === true) {
              this.confirmTypeMessage = 'First, This is request order or net order. ' +
                'Second, this Item also has been mark as ' + this.itemType.name +
                ', Please confirm you already check Item ' +
                this.receiptItemData.item_part_number + '\'s type is ' + this.itemType.name
            } else {
              if (this.receiptItemData.is_request === true) this.confirmTypeMessage = 'This is request order or net order'
              if (this.itemType.requestCheck === true) {
                this.confirmTypeMessage = 'This Item has been mark as ' + this.itemType.name +
                  ', Please confirm you already check Item ' +
                  this.receiptItemData.item_part_number + '\'s type is ' + this.itemType.name
              }
            }
            this.dialogConfirmType = true
          } else {
            if (this.editedItem.task_status_id !== 0) {
              this.submitSaveData()
            }
          }
        }
      },
      submitSaveData () {
        receiptItemTest.store(this.editedItem).then(response => {
          this.receiptItemInfo(this.$route.params.id)
        }).catch(error => {
          console.log(error)
        })
        if (this.editedItem.comment !== null) { receiptItemCommentApi.store(this.receiptItemData.item_receipt.id, this.receiptItemData.id, this.editedItem.comment) }
        this.close()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      deleteItemConfirm () {
        receiptItemTest.delete(this.editedItem.id).then(() => {
          this.receiptItemInfo(this.itemReceiptItemId)
        }).catch(error => {
          console.log(error)
        })
        this.closeDelete()
      },
      commentReceiptItem () {
        receiptItemCommentApi.store(
          this.receiptItemData.item_receipt.id,
          this.receiptItemData.id,
          this.comment,
        ).then(response => {
          this.receiptItemInfo(this.receiptItemData.id)
        }).catch(error => {
          console.log(error)
        })
      },
      displayCommentData () {
        if (this.commentType === 'itemReceiptComment') {
          this.displayComment = Object.assign({}, this.receiptItemData.item_receipt_comment)
        } else {
          this.displayComment = Object.assign({}, this.receiptItemData.comment.filter(comment => comment.reply_type === this.commentType))
        }
      },
      deleteComment (id) {
        receiptItemCommentApi.destroy(id).then(() => {
          this.receiptItemInfo(this.itemReceiptItemId)
        })
      },
    },
  }
</script>

<style scoped>

</style>
