<template>
  <v-btn
    class="mr-0 mt-2"
    color="info"
    min-width="100"
    block
    @click="action(info)"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
  import followApi from '../../../api/follow'
  export default {
    name: 'FollowButton',
    props: {
      info: {
        type: Object,
      },
    },
    data () {
      return {
        followStatus: 0,
      }
    },
    computed: {
      buttonText () {
        return this.followStatus === 0 ? 'Follow' : 'unfollow'
      },
    },
    watch: {
      info (val) {
        if (val.length > 0) {
          switch (val.dataType) {
            case 'itemReceipt':
              this.receipt(val)
              break
            case 'receiptItem':
              this.receiptItem(val)
              break
          }
        }
      },
    },
    methods: {
      receipt (data) {
        followApi.isFollowReceipt(data.id).then(response => {
          this.followStatus = response.data.data
          console.log(this.followStatus)
        })
      },
      receiptItem (data) {
        followApi.isFollowReceiptItem(data.id).then(response => {
          this.followStatus = response.data.data
          console.log(this.followStatus)
        })
      },
      action (data) {
        if (data.dataType === 'itemReceipt') {
          if (this.followStatus === 1) {
            followApi.unFollowReceipt(data.id).then(() => {
              this.followStatus = 0
            })
          } else {
            followApi.followReceipt(data.id).then(() => {
              this.followStatus = 1
            })
          }
        } else {
          if (this.followStatus === 1) {
            followApi.unFollowReceiptItem(data.id).then(() => {
              this.followStatus = 0
            })
          } else {
            followApi.followReceiptItem(data.id).then(() => {
              this.followStatus = 1
            })
          }
        }
      },
    },
  }
</script>

<style scoped>

</style>
